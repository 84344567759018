import { AxiosResponse } from "axios";
import Axios from "./axios";

class Smartcard {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    create(smartcardNumber: string, captcha: string): Promise<AxiosResponse> {
        return this.axios.post(`/smartcard/`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            smartcard_number: smartcardNumber,
            captcha,
        });
    }

    getPackage(
        smartcardNumber: string,
        captcha: string
    ): Promise<AxiosResponse> {
        return this.axios.post(`/smartcard/dstv-package/`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            smartcard_number: smartcardNumber,
            captcha,
        });
    }
}

export default new Smartcard(Axios);

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","xl":"6","offset-xl":"3"}},[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v("DStv Package Checker")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","xl":"6","offset-xl":"3"}},[_c('h6',{staticClass:"text-h6 mb-2"},[_vm._v(" Enter your "),_c('router-link',{attrs:{"exact":"","to":{
                        name: _vm.$constants.ROUTE_NAMES
                            .FAQ_HOW_TO_CHECK_YOUR_DSTV_SMARTCARD_NUMBER,
                    }}},[_vm._v("smartcard")]),_vm._v(" number to get your current DStv package and the expiry date date of your DStv subscription. ")],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","xl":"6","offset-xl":"3"}},[_c(VTextField,{attrs:{"outlined":"","label":"DStv Smartcard Number","type":"number","clearable":"","background-color":_vm.$vuetify.theme.dark ? null : 'white',"disabled":_vm.isSubmitting,"persistent-placeholder":"","placeholder":"e.g 0123456789","hint":"Enter only the first 10 digits of your smartcard number","error":_vm.formInputErrors.has('smartcard_number'),"error-messages":_vm.formInputErrors.get('smartcard_number')},model:{value:(_vm.formSmartCardNumber),callback:function ($$v) {_vm.formSmartCardNumber=$$v},expression:"formSmartCardNumber"}}),_c('div',{class:{
                    'd-flex': _vm.$vuetify.breakpoint.lgAndUp,
                }},[_c('loading-button',{attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"is-loading":_vm.isSubmitting,"large":""},on:{"click":_vm.getDStvPackageDetails}},[_vm._v(" Check Package ")]),_c('p',{staticClass:"text--secondary subtitle-2 mt-3 ml-2"},[_vm._v(" It takes about 10 seconds to fetch the details. ")])],1)],1)],1),_c(VSlideXTransition,[(this.smartcard)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","xl":"6","offset-xl":"3"}},[_c(VAlert,{attrs:{"prominent":_vm.$vuetify.breakpoint.lgAndUp,"color":"success","type":_vm.$vuetify.breakpoint.lgAndUp ? 'success' : null,"text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_vm._v(" Your current package "),(_vm.smartcard && _vm.smartcard.dstvPackage)?_c('span',[_vm._v("is "),_c('b',[_vm._v(_vm._s(this.smartcard.dstvPackage))]),_vm._v(" and it")]):_c('span',[_vm._v(" could not be found and it ")]),(this.smartcard.expiryDate)?_c('span',[_vm._v(" expires on the "),_c('b',{domProps:{"innerHTML":_vm._f("dateHtml")(this.smartcard.expiryDate)}}),_vm._v(". ")]):_c('span',[_vm._v(" was not possible for us to fetch the expiry date. ")])]),_c(VCol,{staticClass:"shrink",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_c(VBtn,{attrs:{"exact":"","to":{
                                    name: _vm.$constants.ROUTE_NAMES.HOME,
                                    query: {
                                        sc: this.smartcard.smartcardNumber,
                                    },
                                }}},[_vm._v(" Pay Subscription ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
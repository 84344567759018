



























































































































import { Component, Vue } from "vue-property-decorator";
import ErrorService, { ValidationErrors } from "@/services/errors";
import AlertBox from "@/components/AlertBox.vue";
import BackButton from "@/components/BackButton.vue";
import { AxiosError, AxiosResponse } from "axios";
import sentry from "@/plugins/sentry";
import SocialButtons from "@/components/SocialButtons.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TextLoadingSpinner from "@/components/TextLoadingSpinner.vue";
import ShareButton from "@/components/ShareButton.vue";
import SmartcardApi from "@/services/api/smartcard";
import Smartcard from "@/models/smartcard";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        ShareButton,
        TextLoadingSpinner,
        LoadingSpinner,
        LoadingButton,
        SocialButtons,
        BackButton,
        AlertBox,
    },
    metaInfo() {
        const title = "Get Your Current DStv Package And Expiry Date.";
        const description =
            "Enter your Smartcard number to get your current DStv package and the expiry date date of your DStv subscription.";
        return {
            title,
            meta: [
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class DstvPackageChecker extends Vue {
    isSubmitting: boolean = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    smartcard: Smartcard | null = null;
    formSmartCardNumber: string = "";

    handleError(error: Error) {
        sentry.captureException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        if (error.response?.status === 500) {
            this.formInputErrors.add(
                "smartcard_number",
                "Could not fetch your DStv package. Please try again later"
            );
        }
        this.handleError(
            new Error(error.response?.data?.message ?? error.message)
        );
    }

    getDStvPackageDetails() {
        this.isSubmitting = true;
        this.smartcard = null;
        this.$recaptcha("dstv_package_checker")
            .then((token: string) => {
                SmartcardApi.getPackage(this.formSmartCardNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("smartcard_number");
                        this.smartcard = new Smartcard(response.data.data);
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            })
            .catch((error) => {
                this.isSubmitting = false;
                this.handleError(error);
            });
    }
}

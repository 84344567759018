export interface SerializedSmartcard {
    customer_name: string;
    smartcard_number: string;
    dstv_package: string | null;
    expiry_date: string | null;
    created_at: string;
}

export default class Smartcard {
    private readonly _smartcardNumber: string;
    private readonly _customerName: string;
    private readonly _createdAt: Date;
    private readonly _dstvPackage: string | null;
    private readonly _expiryDate: Date | null;

    constructor(smartcard: SerializedSmartcard) {
        this._smartcardNumber = smartcard.smartcard_number;
        this._customerName = smartcard.customer_name;
        this._createdAt = new Date(smartcard.created_at);
        this._dstvPackage = smartcard.dstv_package;
        this._expiryDate =
            smartcard.expiry_date === null
                ? null
                : new Date(smartcard.expiry_date);
    }

    get dstvPackage(): string | null {
        return this._dstvPackage;
    }

    get expiryDate(): Date | null {
        return this._expiryDate;
    }

    get smartcardNumber(): string {
        return this._smartcardNumber;
    }

    get customerName(): string {
        return this._customerName;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
}
